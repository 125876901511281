import React, { useEffect, useState } from "react";

import VslSection1 from "./vsl-sections/vsl-section1/vsl-section1.component";
import VslSection2 from "./vsl-sections/vsl-section2/vsl-section2.component";
import VslSection3 from "./vsl-sections/vsl-section3/vsl-section3.component";
import VslSection4 from "./vsl-sections/vsl-section4/vsl-section4.component";
import VslSection5 from "./vsl-sections/vsl-section5/vsl-section5.component";
import VslSection6 from "./vsl-sections/vsl-section6/vsl-section6.component";
import VslSection7 from "./vsl-sections/vsl-section7/vsl-section7.component";
import VslSection8 from "./vsl-sections/vsl-section8/vsl-section8.component";
import VslSection9 from "./vsl-sections/vsl-section9/vsl-section9.component";
import VslSection14 from "./vsl-sections/vsl-section14/vsl-section14.component";
import VslSection12 from "./vsl-sections/vsl-section12/vsl-section12.component";
import VslSection13 from "./vsl-sections/vsl-section13/vsl-section13.component";
import VslSection10 from "./vsl-sections/vsl-section10/vsl-section10.component";
import VslSection15 from "./vsl-sections/vsl-section15/vsl-section15.component";
import VslSection11 from "./vsl-sections/vsl-section11/vsl-section11.component";
import "./vsl.styles.scss";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../components/button-primary/button-primary.component";
const VSL = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true); // Automatically show modal when page loads
  }, []);

  const handleClose = () => setShow(false);
  return (
    <div className="vsl-component">
      <VslSection1 />
      <VslSection2 />
      <VslSection3 />
      <VslSection4 />
      <VslSection5 />
      <VslSection6 />
      <VslSection7 />
      <VslSection8 setShow={setShow} />
      <VslSection9 />
      <VslSection10 />
      <VslSection11 />
      <VslSection12 />
      <VslSection13 />
      <VslSection14 />
      <VslSection15 />
      <Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        <h1>SCHEDULE YOUR CALL BELOW</h1>
        <Modal.Body>
          
          {/* <iframe
            src="https://www.cnvrsnly.com/widget/booking/OpnkE1qxeFR7a6j4sEfB"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "700px",
              border: "none",
              overflow: "hidden",
            }}
            scrolling="no"
            id="ZRiHYlv8tkHyGWxJUaXQ_1712351353153"
          ></iframe> */}
          <iframe
            src="https://www.cnvrsnly.com/widget/booking/mhzIEiGm3YTuWTv7gj6O"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "1000px",
              border: "none",
              overflow: "hidden",
            }}
            scrolling="no"
            id="ZRiHYlv8tkHyGWxJUaXQ_1713985892306"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton variant="secondary" onClick={handleClose}>
            Close
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VSL;
