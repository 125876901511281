import React from "react";

import "./loader.styles.scss";

const Loader = ({ size, borderSize, margin }) => {
  return (
    <div className="outer-container">
      <div
        className="loader-component"
        style={{
          width: size,
          height: size,
          borderWidth: borderSize,
          margin: margin,
        }}
      ></div>
    </div>
  );
};

export default Loader;
