import React from "react";

import Footer from "../../components/footer/footer.component";
import ContactForm from "../../components/contact-form/contact-form.component";
import SmallBanner from "../../components/small-banner/small-banner.component";

import BackgroundEffect from "../../components/background-effect/background-effect.component";

import "./contact.styles.scss";
const Contact = () => {
  return (
    <div className="contact-component">
      <SmallBanner
        title="Let’s Work Together, "
        title2="Don’t be shy to Contact Us."
        heading="At Innovative Mojo, we bring more than 20 years of experience to the table—but we believe that the cornerstone of our success lies in real conversations, with real people, tackling real challenges."
      />
      <ContactForm />

      <div className="footer-container-contact">
        <BackgroundEffect />
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
