import React from "react";
import { useNavigate } from "react-router-dom";
import insta from "../../assets/home/insta.svg";
import linkedin from "../../assets/home/linkedin.png";
import facebook from "../../assets/home/facebook.svg";
import Navbarr from "../navbar/navbar.component";
import background from "../../assets/home/background.mp4";
import PrimaryButton from "../button-primary/button-primary.component";

import "./banner-section.styles.scss";
const BannerSection = () => {
  const navigate = useNavigate();
  return (
    <div className="banner-component">
      <div className="background-video">
        <video
          className="video-background"
          playsInline={true}
          autoPlay
          loop
          muted
        >
          <source src={background} type="video/mp4" />
        </video>
      </div>
      <Navbarr />
      <div className="section1-content  container">
        <h1>Transforming Businesses, Digitally</h1>
        <p>
          Innovative Mojo crafts digital experiences that ignite business
          transformation and deliver measurable results.
        </p>
        <PrimaryButton onClick={() => navigate("/about")}>
          How We Work
        </PrimaryButton>
        <span className="section-one-footer">
          Follow us
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/innovativemojo/"
          >
            <img src={insta} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/innovative-mojo/"
          >
            <img src={linkedin} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/innovativemojo"
          >
            <img src={facebook} alt="" />
          </a>
        </span>
      </div>
    </div>
  );
};

export default BannerSection;
