import React, { useEffect, useState } from "react";


import logo from "../../assets/home/logo.svg";
import PrimaryButton from "../../components/button-primary/button-primary.component";
import BackgroundEffect from "../../components/background-effect/background-effect.component";

import "./brand-awareness.styles.scss";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

const BrandAwareness = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true); // Automatically show modal when page loads
  }, []);

  const handleClose = () => setShow(false);
  return (
    <div className="brand-awareness-component">
{/* modal start */}
<Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        <h1>SCHEDULE YOUR CALL BELOW</h1>
        <Modal.Body>
          <iframe
            src="https://www.cnvrsnly.com/widget/booking/OpnkE1qxeFR7a6j4sEfB"
            style={{
              width: '100%',
              height:"100%",
              minHeight:"700px" ,
              border: 'none',
              overflow: 'hidden',
            }}
            scrolling="no"
            id="ZRiHYlv8tkHyGWxJUaXQ_1712351353153"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton variant="secondary" onClick={handleClose}>
            Close
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
{/* modal end */}

      <BackgroundEffect classes="top" />
      <div className="business-container">
        <img className="logo" src={logo} alt="" onClick={() => navigate("/")} />
        <div className="engagement-content-container">
          <div className="subtext" > 
            Evolve Into the New Age of Business and Revolutionize Your Website
            to Generate More Clientele
          </div>
          <h1>
            Achieve More Brand Awareness &<span> SEO Dominance </span>
            With Our
          </h1>
          <h1 className="ilatic">Business Boost Blueprint</h1>

          <div className="image-with-description">
            <div className="image-container">
              <img
                src="https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/feaZmLKzYYtZGJH8vlFf/media/656778a15fe005e7f4ae4be3.png"
                alt=""
              />
            </div>
            <div className="descrption-container">
              <span>
                Our Team Of Developers Will Create Unique, Powerful Websites
                That Will Showcase Your Business In The Best Light
              </span>
              <ul>
                <li>Including 24/7 Support and Website Maintenance</li>
                <li>A Digital Solution For Your Technical World</li>
              </ul>
              <PrimaryButton>Get Free Instant Access Now!</PrimaryButton>
            </div>
          </div>
        </div>
        <div className="tablet-button">
          <PrimaryButton onClick={() => setShow(true)}>Get Free Instant Access Now!</PrimaryButton>
        </div>
        <div className="footer">
          © 2024 Innovative Mojo. All Rights Reserved.
        </div>
      </div>
      <BackgroundEffect classes="bottom" />
    </div>
  );
};

export default BrandAwareness;
