import React from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
  usePagination,
} from "react-table";

import "./react-table-new.styles.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import { NewTableStyles } from "../../utils/item";
import TablePagination from "./table.pagination.component";
import GlobalFilter from "./GlobalFilter.component";
import { getRandomId } from "../../utils/paginationFunctions";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const ReactTableComponent = ({
  columns,
  data,
  pagination,
  loading,
  defaultHiddenColumns,

  checkErrors,
  noDataMessage,

  rowCount,
  dashboardTable,
  firstRowFixed,
  firstColumnFixed,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: false,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  React.useEffect(() => {
    if (defaultHiddenColumns) {
      setHiddenColumns(defaultHiddenColumns);
    }
  }, [defaultHiddenColumns, setHiddenColumns]);

  React.useEffect(() => {
    if (pageCount) {
      gotoPage(0);
    }
  }, [pageCount, gotoPage]);

  React.useEffect(() => {
    if (data && pagination) {
      gotoPage(0);
    }
  }, [data, pagination, gotoPage]);

  return (
    <div className="application-table-component ">
      <div className="filters">
        <div className="global-filter-refresh-data">
          <div className="global">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              id={columns.length ? columns[0].Header : getRandomId()}
            />
          </div>
        </div>
      </div>
      <NewTableStyles>
        {!loading && data.length ? (
          <div className="table-parent">
            <div className="table-overflow-container">
              <table
                className={`table ${firstRowFixed ? `first-row-fixed` : ""} ${
                  firstColumnFixed ? "first-column-fixed" : ""
                }`}
                data-index={firstRowFixed}
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) => {
                        return (
                          <th
                            className={`${
                              column.render("Header") ? "header" : "noHeader"
                            } capitalize`}
                            key={i}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <>
                              {column.render("Header") && (
                                <span
                                  style={{
                                    marginLeft: "7px",
                                  }}
                                >
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i
                                        className="fas fa-caret-up"
                                        style={{
                                          color: "gray",
                                          fontSize: "15px",
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fas fa-caret-down"
                                        style={{
                                          color: "gray",
                                          fontSize: "15px",
                                        }}
                                      ></i>
                                    )
                                  ) : (
                                    <i
                                      className="fas fa-sort"
                                      style={{
                                        color: "gray",
                                        fontSize: "15px",
                                      }}
                                    ></i>
                                  )}
                                </span>
                              )}
                            </>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);
                    const error = checkErrors ? row.original.error : false;
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={`${dashboardTable ? "dash-table" : ""} ${
                          error ? "error-field" : ""
                        }`}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              key={i}
                              className={`${
                                typeof cell.value === "number" ? "num" : ""
                              }`}
                              style={{ verticalAlign: "unset" }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TablePagination
              setPageSize={setPageSize}
              rowCount={rowCount}
              pageCount={pageCount}
              preGlobalFilteredRows={preGlobalFilteredRows}
              nextPage={nextPage}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pagination={pagination}
              state={state}
              gotoPage={gotoPage}
            />
          </div>
        ) : loading ? (
          <div className="loadingTable">Loading....</div>
        ) : (
          <span>{noDataMessage ? noDataMessage : "No data available"}</span>
        )}
      </NewTableStyles>
    </div>
  );
};
export default ReactTableComponent;
