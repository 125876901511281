import React from "react";
import Footer from "../footer/footer.component";
import ReadyToStartProject from "../ready-to-start-project/ready-to-start-project.component";

import "./home-footer.styles.scss";

const HomeFooter = () => (
  <div className="home-footer-component">
    <ReadyToStartProject />
    <Footer />
  </div>
);

export default HomeFooter;
