import React from "react";
import "./new-heights.styles.scss";
const NewHeights = ({ process, image }) => {
  return (
    <div className="new-heights-component">
      <div className="container mx-auto new-heights">
        <div className="content">
          <i className="fas fa-tablet-screen-button"></i>
          <h2>Process</h2>
          <div className="subtext">{process}</div>
        </div>
        <div className="heights-images-container">
          <img className="height-img" src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default NewHeights;
