import React, { useState, useEffect } from "react";

const NumberAnimation = ({ target, duration, delay, currentSlide, places }) => {
  const [count, setCount] = useState(1);
  const stepTime = Math.abs(Math.floor(duration / target));
  // Inside NumberAnimation component
  useEffect(() => {
    if (currentSlide === 1) {
      setCount(1);
      const timer = setTimeout(() => {
        const interval = setInterval(() => {
          setCount((prevCount) =>
            prevCount < target ? prevCount + 1 : prevCount
          );
        }, stepTime);

        // Cleanup interval
        return () => clearInterval(interval);
      }, delay); // delay before starting the animation

      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [target, stepTime, delay, currentSlide]); // delay added to dependency list

  return (
    <div className="number-animation">
      {count.toString().padStart(places, "0")}
    </div>
  );
};

export default NumberAnimation;
