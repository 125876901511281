import React from "react";
import PrimaryButton from "../button-primary/button-primary.component";
import logo from "../../assets/home/logo-white.svg";

import "./about-section.styles.scss";
import BackgroundEffect from "../background-effect/background-effect.component";
import { useNavigate } from "react-router-dom";
import NumberAnimation from "../number-animation/number-animation.component";

const stats = [
  {
    amount: 20,
    text: "Years Experience",
    duration: 700,
    delay: 700,
    place: 2,
  },
  { amount: 700, text: "Happy Clients", duration: 1000, delay: 0, place: 3 },
  {
    amount: 1200,
    text: "Successful Projects",
    duration: 2000,
    delay: 0,
    place: 4,
  },
];
const AboutSection = ({ currentSlide }) => {
  const navigate = useNavigate();
  return (
    <div className="about-section-component ">
      <BackgroundEffect />
      <div className="section-container container">
        <div className="about-details-container">
          <div className="about-container">
            <h3>Comprehensive Digital Transformation Tailored for Success</h3>
            <div className="text-content">
              At Innovative Mojo, we're excited to introduce our groundbreaking
              all-in-one solution designed to revolutionize your online
              presence. Our holistic approach combines advanced SEO tactics,
              efficient ad optimization, bespoke website design, and compelling
              content creation, all streamlined into a singular,
              powerful strategy. <br /> <br /> From custom React web designs
              that enhance user engagement to SEO strategies that move the
              needle, and native apps that open new revenue streams—our
              solutions are designed to transform your business and deliver
              measurable results. We pride ourselves on being accessible,
              transparent, and relentlessly focused on results. When you choose
              Innovative Mojo, you're not just hiring a service provider; you're
              gaining a team dedicated to your business's long-term success.
              <PrimaryButton onClick={() => navigate("/booking")}>
              Lets Discuss
              </PrimaryButton>
            </div>
          </div>
        </div>
        <div className="success-stats-container">
          <div className="logo-container-mobile">
            <img src={logo} alt="" />
          </div>
          <div className="text-content">
            <h3>Marketing Matches Your Success Story</h3>

            <PrimaryButton onClick={() => navigate("/booking")}>
            Lets Discuss
            </PrimaryButton>
          </div>
          <div className="stats-container">
            {stats.map((stat, i) => (
              <div className="amount-container" key={i}>
                <div className="amount">
                  <NumberAnimation
                    currentSlide={currentSlide}
                    target={stat.amount}
                    duration={stat.duration}
                    delay={stat.delay}
                    places={stat.place}
                  />
                  <span className="amount-span">{stat.amount}</span>+
                </div>
                <div className="subtext">{stat.text}</div>
              </div>
            ))}
          </div>
          <div className="logo-container">
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
