import React from "react";
import PrimaryButton from "../../../../components/button-primary/button-primary.component";
import "./vsl-section3.styles.scss";
import { useNavigate } from "react-router-dom";
const VslSection3 = () => {
  const navigate = useNavigate();
  return (
    <div className="section3-component">
      <div className="container">
        <div className="subtext">
          WE’VE HELPED MANY BUSINESS OWNERS, ENTREPRENEURS, AND GOVERNMENT
          CONTRACTORS
        </div>
        <h1>
          <span>
            CUSTOMIZE VISUALLY APPEALING AND USER FRIENDLY WEBSITES TO SCALE
          </span>
        </h1>
        <PrimaryButton onClick={() => navigate("/booking")}>
          I’m Ready To Outrank My Competitors
        </PrimaryButton>
      </div>
    </div>
  );
};

export default VslSection3;
