import React, { useState } from "react";
import VectorRight from "../../assets/VectorRight.png";
import VectorLeft from "../../assets/VectorLeft.png";
import { filterPages, getVisiblePages } from "../../utils/paginationFunctions";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { paginationOptions } from "../../utils/paginationFunctions";

const TablePagination = ({
  setPageSize,
  rowCount,
  pageCount,
  preGlobalFilteredRows,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pagination,
  state,
  gotoPage,
}) => {
  const [visiblePages, setVisiblePages] = useState([]);
  const [show, setShow] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(rowCount ? rowCount : 10);
  const [seeAll, setSeeAll] = useState(false);
  React.useEffect(() => {
    const pages = getVisiblePages(null, pageCount);
    setVisiblePages(pages);
  }, [pageCount]);

  React.useEffect(() => {
    if (seeAll) setPageSize(preGlobalFilteredRows.length);
    else setPageSize(rowPerPage);
  }, [setPageSize, preGlobalFilteredRows, seeAll, rowPerPage]);

  return (
    <>
      {pagination && (
        <div className="pagination">
          <div className="pagination-btns">
            <img
              onClick={() => {
                const visiblePages = getVisiblePages(
                  state.pageIndex - 2,
                  pageCount
                );
                setVisiblePages(filterPages(visiblePages, pageCount));
                previousPage();
              }}
              disabled={!canPreviousPage}
              src={VectorLeft}
              alt=""
            />
            <div className="pageCount">
              {visiblePages.length > 0 &&
                visiblePages.map((page, index, array) => {
                  return (
                    <div
                      key={page}
                      className={`pageNumbers ${
                        state.pageIndex + 1 === page ? "active" : ""
                      }`}
                      onClick={() => {
                        const visiblePages = getVisiblePages(page, pageCount);
                        setVisiblePages(filterPages(visiblePages, pageCount));

                        gotoPage(page - 1);
                      }}>
                      {array[index - 1] + 2 < page ? `..... ${page}` : page}
                    </div>
                  );
                })}
            </div>
            <img
              onClick={() => {
                const visiblePages = getVisiblePages(
                  state.pageIndex + 2,
                  pageCount
                );
                setVisiblePages(filterPages(visiblePages, pageCount));
                nextPage();
              }}
              disabled={!canNextPage}
              src={VectorRight}
              alt=""
            />
          </div>
          <div className="pagination-dropdown">
            <DropdownButton
              id="dropdown-basic-button"
              className="Search"
              onClick={() => {
                setShow(!show);
              }}
              title={
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}>
                  {`${seeAll ? "All" : rowPerPage} rows`}
                  {show ? (
                    <i
                      className="fas fa-caret-up"
                      style={{
                        color: "var(--secondary-color)",
                        fontSize: "25px",
                        marginLeft: "5px",
                      }}></i>
                  ) : (
                    <i
                      className="fas fa-caret-down"
                      style={{
                        color: "var(--secondary-color)",
                        fontSize: "25px",
                        marginLeft: "5px",
                      }}></i>
                  )}
                </span>
              }>
              {paginationOptions.map((item, i) => (
                <Dropdown.Item
                  onClick={() => {
                    setSeeAll(false);
                    setRowPerPage(item.value);
                  }}
                  key={i}>
                  <div className="dropDown-options">
                    <span>{item.title}</span>
                  </div>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </div>
      )}
    </>
  );
};
export default TablePagination;
