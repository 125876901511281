import React from "react";

import BackgroundEffect from "../background-effect/background-effect.component";

import "./benefits-section.styles.scss";
const benefits = [
  {
    title: "Step 1: Discovery & Consultation",
    description:
      "We begin with a personalized consultation to understand your business goals, challenges, and vision for success. Your insights are the foundation of our strategy.",
  },
  {
    title: "Step 2: Research & Analysis",
    description:
      "Using the information gathered, we conduct thorough market research and competitive analysis. This helps us understand your industry landscape and identify opportunities to stand out.",
  },
  {
    title: "Step 3: Strategy & Planning",
    description:
      "We develop a customized plan that blends SEO techniques with compelling brand storytelling through video. This strategy outlines deliverables, timelines, and milestones, ensuring that every step aligns with your objectives.",
  },
  {
    title: "Step 4: Creative Development",
    description:
      "Our creative team takes charge, producing high-quality videos and digital content that resonate with your audience. Whether it’s a captivating commercial or engaging social media content, we ensure that your brand message is clear and powerful.",
  },
  {
    title: "Step 5: Optimization & Quality Assurance",
    description:
      "Before launch, we rigorously test and optimize all content for SEO performance and audience engagement. We focus on usability, responsiveness, and maximizing your reach across digital platforms.",
  },
  {
    title: "Step 6: Launch & Continuous Improvement",
    description:
      "After your approval, we launch your campaign, but our partnership doesn’t stop there. We provide ongoing support, performance analytics, and optimization to ensure your brand continues to thrive and grow.",
  },
];
const BenefitsSection = () => {
  return (
    <div className="benefits-section-component">
      <h2>Our Process: Crafting Your Digital Success</h2>

      <BackgroundEffect />
      <div className="container row benefits-container">
        <div className="sub-banner">
          <p>
            At Innovative Mojo, we combine creativity with strategic thinking to
            bring your brand to life. Our structured six-step process ensures
            that we deliver impactful solutions tailored to your unique business
            needs, always striving to exceed your expectations.
          </p>
          <br />
        </div>
        {benefits.map((benefit, i) => (
          <div className="col-lg-4 col-12 wrapper" key={i}>
            <div className="benefit-container">
              <div className="benefit-title">{benefit.title}</div>
              <div className="benefit-description">{benefit.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;
