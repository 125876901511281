import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { ReactComponent as Cross } from "../../assets/cross-modal.svg";

import "./featured-articles-modal.styles.scss";
import Select from "react-select";
import Swal from "sweetalert2";
import { functions } from "../../firebase/firebase";
import PrimaryButton from "../button-primary/button-primary.component";

const getArticlesOptions = (articles, featured) => {
  const keys = Object.values(featured);
  const options = articles
    .filter((item) => !keys.includes(item.id))
    .map((item) => ({
      label: item.title,
      value: item.id,
    }));
  return options;
};

const ArticleFeatureModal = ({ show, onHide, articles }) => {
  const [featured, setFeatured] = useState({
    option1: "",
    option2: "",
    option3: "",
  });
  const [featuredSelected, setFeaturedSelected] = useState({
    option1: { label: "", value: "" },
    option2: { label: "", value: "" },
    option3: { label: "", value: "" },
  });

  useEffect(() => {
    if (articles.length) {
      const featuredArticles = articles.filter((item) => item.isFeatured);
      const obj = {};
      const selectedObj = {};
      if (featuredArticles.length) {
        featuredArticles.forEach((article) => {
          obj[`option${article.featuredRank}`] = article.id;
          selectedObj[`option${article.featuredRank}`] = {
            label: article.title,
            value: article.id,
          };
        });
        setFeatured(obj);
        setFeaturedSelected(selectedObj);
      }
    }
  }, [articles]);
  const handleChange = (e, title) => {
    const oldFeatured = { ...featured };
    const oldFeaturedSelected = { ...featuredSelected };
    oldFeatured[title] = e.value;
    oldFeaturedSelected[title] = e;
    setFeatured(oldFeatured);
    setFeaturedSelected(oldFeaturedSelected);
  };
  const validateOptions = (options) => {
    const hasValue = Object.values(options).some((value) => Boolean(value));
    return hasValue;
  };
  const onHideModal = () => {
    setFeatured({
      option1: "",
      option2: "",
      option3: "",
    });
    onHide();
  };
  const handleSubmit = async () => {
    if (validateOptions(featured)) {
      Swal.fire({
        icon: "info",
        title: "Making Articles Featured",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const makeFeaturedArticles = functions.httpsCallable(
        "makeFeaturedArticles"
      );
      const resp = await makeFeaturedArticles(featured);
      if (resp.data.success) {
        Swal.fire({
          icon: "success",
          title: "Making Articles Featured",
          text: "Successfully Made Articles Featured",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            onHideModal();
          }
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select a value",
      });
    }
  };

  return (
    <div>
      <Modal
        className="articles-modal fade"
        onHide={onHideModal}
        centered={true}
        backdrop="static"
        onEscapeKeyDown="false"
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <div className="custom-modal-header">
            <span>Feature Articles</span>

            <Cross onClick={onHideModal} />
          </div>
          <div className="articles-options">
            <div className="email-options">
              {/* <span>Priority 1</span> */}
              <Select
                options={getArticlesOptions(articles, featured)}
                onChange={(e) => handleChange(e, "option1")}
                value={featuredSelected.option1}
              />
            </div>
          </div>
          <div className="articles-options">
            <div className="email-options">
              {/* <span>Priority 2</span> */}
              <Select
                options={getArticlesOptions(articles, featured)}
                onChange={(e) => handleChange(e, "option2")}
                value={featuredSelected.option2}
              />
            </div>
          </div>
          <div className="articles-options">
            <div className="email-options">
              {/* <span>Priority 3</span> */}
              <Select
                options={getArticlesOptions(articles, featured)}
                onChange={(e) => handleChange(e, "option3")}
                value={featuredSelected.option3}
              />
            </div>
          </div>

          <div className="articles-footer">
            <PrimaryButton noarrow onClick={handleSubmit}>
              Proceed
            </PrimaryButton>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ArticleFeatureModal;
