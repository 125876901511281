import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../../../assets/home/logo.svg";
import arrow from "../../../../assets/arrow.png";

import "./vsl-section1.styles.scss";
const VslSection1 = () => {
  const scrollToNextSection = () => {
    const scrollHeight = window.innerHeight;
    window.scrollTo(0, scrollHeight);
  };
  const navigate = useNavigate();
  return (
    <div className="section1-component">
      <div className="container">
        <img src={logo} alt="" className="logo" onClick={() => navigate("/")} />
        <div className="subtext">
          DIVE INTO OUR CLIENTS SUCCESS STORY AND LEARN
        </div>
        <h1>
          HOW WE HELPED SCALE THEIR <br /> INNOVATIVE IDEA INTO WHAT IS NOW
          <br />
          <span>
            A MULTI-MILLION DOLLAR <br /> TAILGATING REAL ESTATE EMPIRE!
          </span>
        </h1>
        <img
          className="scroll-down"
          src={arrow}
          alt=""
          onClick={scrollToNextSection}
        />
      </div>
    </div>
  );
};

export default VslSection1;
