import React, { useState } from "react";
import { InlineWidget } from "react-calendly";
import firebase from "../../firebase/firebase";
import { Dropdown } from "react-bootstrap";
import Loader from "../../components/loader/loader.component";
import BackgroundEffect from "../background-effect/background-effect.component";

import "./contact-form.styles.scss";
import PrimaryButton from "../button-primary/button-primary.component";
const initial = {
  fullName: "",
  emailAddress: "",
  contactNumber: "",
  projectGoal: "",
  projectURL: "",
  projectName: "",
  projectTimeframe: "",
  budget: false,
  numPages: "",
  hasUI: "",
  likedProjects: "",
  additionalNotes: "",
  bestTimeToReach: "morning",
  needMaintenance: true,
  needEcommerce: true,
  hasContent: false,
  serviceReference: "Which of our services is this in reference to?",
};
const ContactForm = () => {
  const [showCalender, setShowCalender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initial);

  const handleSubmit = async (event) => {
    setLoading(true);
    setShowCalender(true);
    event.preventDefault();
    console.log(formData);
    const sendFormData = firebase
      .functions()
      .httpsCallable("sendFormDataInEmail");
    const resp = await sendFormData(formData);
    console.log(resp);
    if (resp) {
      setLoading(false);
      setFormData(initial);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleRadio = (e) => {
    const { name, value } = e;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDropdownChange = (value) => {
    setFormData((prevData) => ({ ...prevData, serviceReference: value }));
  };

  return (
    <div className="contact-form-component container">
      <BackgroundEffect />

      {showCalender ? (
        <div>
          {loading && <Loader size="50px" borderSize="5px" />}
          <InlineWidget
            height="100%"
            url="https://calendly.com/projectlead-5xm/introductory-call"
          />
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="Email Address"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="What is your goal for this project?"
                name="projectGoal"
                value={formData.projectGoal}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="Current project URL"
                name="projectURL"
                value={formData.projectURL}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="What is the Name of your project?"
                name="projectName"
                value={formData.projectName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="What is your time-frame for this project?"
                name="projectTimeframe"
                value={formData.projectTimeframe}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrapper col-lg-6  radio-row ">
              <span>Is your monthly Revenue above 7k?</span>
              <div className="row">
                <label
                  className="col-lg-6"
                  onClick={() =>
                    handleRadio({
                      name: "budget",
                      value: true,
                    })
                  }
                >
                  <div
                    className={`custom-radio ${
                      formData.budget ? "active" : ""
                    }`}
                  ></div>
                  <p>YES</p>
                </label>
                <label
                  className="col-lg-6"
                  onClick={() =>
                    handleRadio({
                      name: "budget",
                      value: false,
                    })
                  }
                >
                  <div
                    className={`custom-radio ${
                      !formData.budget ? "active" : ""
                    }`}
                  ></div>
                  <p>No</p>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="How many pages do you need?"
                name="numPages"
                value={formData.numPages}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-wrapper col-lg-6 ">
              <input
                type="text"
                placeholder="Do you have a website UI?"
                name="hasUI"
                value={formData.hasUI}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 input-wrapper">
              <input
                type="text"
                placeholder="Give examples of projects you like?"
                name="likedProjects"
                value={formData.likedProjects}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-lg-6 input-wrapper">
              <input
                type="text"
                placeholder="Anything else you would like us to know?"
                name="additionalNotes"
                value={formData.additionalNotes}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row radio-buttons">
            <div className="input-wrapper col-lg-6 ">
              <span>Best Time To Reach?</span>
              <label
                onClick={() =>
                  handleRadio({
                    name: "bestTimeToReach",
                    value: "morning",
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    formData.bestTimeToReach === "morning" ? "active" : ""
                  }`}
                ></div>
                <p>Morning</p>
              </label>
              <label
                onClick={() =>
                  handleRadio({
                    name: "bestTimeToReach",
                    value: "afternoon",
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    formData.bestTimeToReach === "afternoon" ? "active" : ""
                  }`}
                ></div>
                <p>Afternoon</p>
              </label>
              <label
                onClick={() =>
                  handleRadio({
                    name: "bestTimeToReach",
                    value: "evening",
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    formData.bestTimeToReach === "evening" ? "active" : ""
                  }`}
                ></div>
                <p>Evening</p>
              </label>
            </div>
            <div className="input-wrapper col-lg-6 ">
              <span>Do you need monthly maintenance?</span>
              <label
                onClick={() =>
                  handleRadio({
                    name: "needMaintenance",
                    value: true,
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    formData.needMaintenance ? "active" : ""
                  }`}
                ></div>
                <p>YES</p>
              </label>
              <label
                onClick={() =>
                  handleRadio({
                    name: "needMaintenance",
                    value: false,
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    !formData.needMaintenance ? "active" : ""
                  }`}
                ></div>
                <p>NO</p>
              </label>
            </div>
          </div>
          <div className="row radio-buttons">
            <div className="input-wrapper col-lg-6 ">
              <span>Do you need E-commerce?</span>
              <label
                onClick={() =>
                  handleRadio({
                    name: "needEcommerce",
                    value: true,
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    formData.needEcommerce ? "active" : ""
                  }`}
                ></div>
                <p>YES</p>
              </label>
              <label
                onClick={() =>
                  handleRadio({
                    name: "needEcommerce",
                    value: false,
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    !formData.needEcommerce ? "active" : ""
                  }`}
                ></div>
                <p>No</p>
              </label>
            </div>
            <div className="input-wrapper col-lg-6 ">
              <span>Do you have content for the project?</span>
              <label
                onClick={() =>
                  handleRadio({
                    name: "hasContent",
                    value: true,
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    formData.hasContent ? "active" : ""
                  }`}
                ></div>
                <p>YES</p>
              </label>
              <label
                onClick={() =>
                  handleRadio({
                    name: "hasContent",
                    value: false,
                  })
                }
              >
                <div
                  className={`custom-radio ${
                    !formData.hasContent ? "active" : ""
                  }`}
                ></div>
                <p>NO</p>
              </label>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12 input-wrapper dropdown-container">
              <Dropdown onSelect={handleDropdownChange}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {formData.serviceReference}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="React Development">
                    React Development
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Mobile App Development">
                    Mobile App Development
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="SEO">SEO</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <br /> <br />
          <div className="d-flex justify-content-center">
            <PrimaryButton disabled={loading} onClick={handleSubmit}>
              Submit
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
