import React from "react";
import "./vsl-section12.styles.scss";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/button-primary/button-primary.component";
const VslSection12 = () => {
  const navigate = useNavigate();
  return (
    <div className="section12-component">
      <div className="container">
        <div className="subtext">LET US GIVE YOU A SMALL TASTE!</div>
        <h1>
          BOOK A 30 MINUTE DEMO WITH ONE OF OUR SUPER FRIENDLY PRODUCT
          SPECIALISTS!
        </h1>
        <PrimaryButton onClick={() => navigate("/booking")}>
          <div className="button-flex">
            <h5>Act Now and Receive</h5>
            <div>30 Days of FREE Maintenance</div>
          </div>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default VslSection12;
