import React from "react";
import Footer from "../../components/footer/footer.component";
import SmallBanner from "../../components/small-banner/small-banner.component";

import "./terms-and-conditions.styles.scss";

const TermsAndConditions = () => {
  return (
    <div className="privacy-policy-component">
      <SmallBanner
        title="Terms and Conditions"
        heading="At Innovative Mojo, we bring more than 20 years of experience to the table—but we believe that the cornerstone of our success lies in real conversations, with real people, tackling real challenges."
      />
      <div className="container conditions-container">
        <h3>Introduction</h3>
        <p>
          This document governs the relationship between the Client and
          InnovativeMojo ("The Company"). Scope of Work
        </p>
        <p>
          The specific services to be provided will be outlined in each
          individual client contract. Ownership
        </p>
        <p>
          All funnels, graphics, and builds created during the project will
          remain the property of the Client. Portfolio Use
        </p>
        <p>
          The Company retains the right to use any created funnels, graphics,
          and builds in its portfolio, marketing materials, and case studies
          unless a Non-Disclosure Agreement (NDA) has been signed by the Client
          that explicitly restricts this use.
        </p>
        <h3>What personal data we collect and why we collect it</h3>
        <h3>Payments</h3>
        <p>
          Terms around deposits, payment plans, and cancellations will be
          specified in the individual client contract.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          Any disputes arising out of this agreement shall be resolved through
          mutual discussion, failing which legal avenues may be explored.
        </p>
        <h3>Termination</h3>
        <p>
          Either party may terminate the contract under conditions outlined in
          the individual client contract. Legal Jurisdiction
        </p>
        <p>
          This agreement shall be governed by the laws of the State of
          California.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
