import React, { useState } from "react";

import FAQItem from "../faq-item/faq-item.component";
import "./faq.styles.scss";
import BackgroundEffect from "../background-effect/background-effect.component";

export const faqData = [
  {
    question: "1. What services does Innovative Mojo offer?",
    answer:
      "We offer a comprehensive range of digital solutions, including brand development,full content creation, website design and maintenance, SEO, digital marketing, and full commercial production. Our goal is to provide end-to-end support to elevate your brand's online presence.",
  },
  {
    question: "2. How can Innovative Mojo help build my brand from scratch?",
    answer:
      "We start with in-depth consultations to understand your vision and goals. Our team then creates a custom strategy, including logo design, brand messaging, and a cohesive online presence, to ensure your brand stands out.",
  },
  {
    question: "3. What does your SEO service include?",
    answer:
      "Our SEO services encompass keyword research, on-page and off-page optimization, content creation, link building, and performance tracking. We aim to improve your search engine rankings and drive organic traffic to your website.",
    // line2: "React Web Design: Approximately 45 days",
    // line3: "Mobile Applications: Around 60 days",
    // line4: "SEO: Generally 3 months",
  },
  {
    question: "4. How often do you update and maintain websites?",
    answer:
      "We offer ongoing website maintenance that includes regular updates, security checks, performance optimization, and content updates to ensure your website remains up-to-date and secure.",
  },
  {
    question: "5. Can you handle our social media marketing?",
    answer: "Absolutely! We create and manage social media campaigns across various platforms, crafting engaging content and strategies to increase your brand’s visibility and engagement with your target audience.",

  },
  {
    question: "6. Do you provide analytics and reporting?",
    answer:
      "Yes, we provide detailed analytics and reporting for all our services. This includes website performance, SEO progress, and digital marketing campaign results, helping you understand the impact of our efforts.",
  },
  {
    question: "7. What industries do you specialize in?",
    answer:
      "We work with a diverse range of industries, tailoring our services to meet the unique needs of each client. Whether you’re in healthcare, technology, retail, or any other sector, we can help.",
  },
  {
    question: "8. How do you ensure the quality of your video advertisements?",
    answer:
      "Our team includes experienced videographers and editors who use the latest technology and techniques to produce high-quality videos. We work closely with you to ensure the content aligns with your brand message and goals.",
  },
  {
    question: "9. What sets Innovative Mojo apart from other digital marketing agencies?",
    answer:
      "Our all-in-one approach, combined with personalized service and a commitment to excellence, sets us apart. We offer a complete suite of digital solutions under one roof, ensuring consistency and synergy across all aspects of your online presence.",
  },
  {
    question: "10. How can I get started with Innovative Mojo?",
    answer:
      "Simply contact us to schedule a consultation. We’ll discuss your needs, goals, and how our services can help you achieve success. From there, we’ll develop a tailored strategy to get started.",
  },
  {
    question: "11. Do you offer any special packages or discounts?",
    answer:
      "Yes, we offer various packages tailored to different business needs and budgets. Contact us to learn more about our current promotions and how we can customize a package for you.",
  },
  {
    question: "12: What is the cost?",
    answer:
      "We offer different packages and can tailor a solution that not only fits your brand, but also your budget. Contact us to get more details.",
  },
];

const FAQ = ({ faq }) => {
  const [isOpen, setIsOpen] = useState({ index: 0, status: false });

  const toggle = (index, status) => {
    setIsOpen({ index, status });
  };

  const newData = faq || faqData;
  return (
    <div className="faq-component container">
      <BackgroundEffect />
      {!faq && <h2>Frequently Asked Questions</h2>}
      <div className="faq-list">
        {newData.map((item, index) => (
          <FAQItem
            key={index}
            index={index}
            toggle={toggle}
            isOpen={isOpen}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
