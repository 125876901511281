import React from "react";

// import { ReactComponent as Icon } from "../../assets/home/button-arrow.svg";

import "./ready-to-start-project.styles.scss";
import BackgroundEffect from "../background-effect/background-effect.component";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../button-primary/button-primary.component";

const ReadyToStartProject = () => {
  const navigate = useNavigate();
  return (
    <div className="ready-to-start-project-component">
      <BackgroundEffect />
      <div className="ready-to-start-project container">
        <div className="ready-to-start-project__content">
          <h2>Ready To Start Your Project?</h2>

          <PrimaryButton onClick={() => navigate("/booking")}>
            <span className="button-text">Lets Discuss</span>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
export default ReadyToStartProject;
