import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/home/logo.svg";
import triangle from "../../assets/triangle.svg";

import "./thankyou.styles.scss";
import VslSection15 from "../vsl/vsl-sections/vsl-section15/vsl-section15.component";
const Thankyou = () => {
  const [play, setPlay] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="thankyou-component">
      <div className="business-container container">
        <img src={logo} alt="" className="logo" onClick={() => navigate("/")} />
        <div className="thankyou-header">
          <h1>CONGRATULATIONS!</h1>
          <div className="semi-sub">YOUR APPOINTMENT HAS BEEN RESERVED!!</div>
          <div className="subtext">
            Someone from our team will be reaching out to confirm your time. 
            <br />
            You’re almost done! Follow these next 2 steps.
          </div>
        </div>
        <div className="thankyou-content">
          <h2>STEP 1:</h2>
          <div className="heading">
            PLEASE WATCH THIS VIDEO BEFORE YOUR CALL
          </div>
          <div className="video-container">
            {!play ? (
              <div className="play-container" onClick={() => setPlay(!play)}>
                <img src={triangle} alt="" />
              </div>
            ) : (
              <iframe
                src="https://player.vimeo.com/video/777446815?h=1674684d89&amp;amp;app_id=122963&amp;autoplay=1&amp;controls=1"
                allow="autoplay"
                title="player"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            )}
          </div>
          <h2>STEP 2:</h2>
          <div className="content-containers">
            <div className="section-content-container">
              <div className="subtext">FOLLOW US ON</div>
              <h3>INSTAGRAM</h3>
              <div className="subtext">
                To Learn All The Strategies For Success!
              </div>
            </div>
            <div className="section-content-container">
              <div className="subtext">FOLLOW US ON</div>
              <h3>FACEBOOK</h3>
              <div className="subtext">
                To Receive Further Support From Our Team!
              </div>
            </div>
          </div>
        </div>
      </div>
      <VslSection15 />
    </div>
  );
};

export default Thankyou;
