import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/home/logo.svg";
import "./engagement.styles.scss";
import PrimaryButton from "../../components/button-primary/button-primary.component";
import { Modal } from "react-bootstrap";

const Engagement = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true); // Automatically show modal when page loads
  }, []);

  const handleClose = () => setShow(false);
  return (
    <div className="engagement-component">
      <Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        <h1>SCHEDULE YOUR CALL BELOW</h1>
        <Modal.Body>
          <iframe
            src="https://www.cnvrsnly.com/widget/booking/OpnkE1qxeFR7a6j4sEfB"
            style={{
              width: '100%',
              height:"100%",
              minHeight:"700px" ,
              border: 'none',
              overflow: 'hidden',
            }}
            scrolling="no"
            id="ZRiHYlv8tkHyGWxJUaXQ_1712351353153"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton variant="secondary" onClick={handleClose}>
            Close
          </PrimaryButton>
        </Modal.Footer>
      </Modal>

      <img src={logo} alt="" className="logo" onClick={() => navigate("/")} />
      <div className="engagement-content-container">
        <div className="subtext">
          We Help Businesses Get More Website Engagement and Brand Awareness in
          60 Days. You Raise <br /> the Bar, We Do the Lifting.
        </div>
        <h1>
          Find Out&nbsp;
          <span>
            How Business <br />
            Owners Are Making $500K
          </span>
        </h1>
        <h1 className="ilatic">
          <i>in Extra Revenue in 2 Months </i>
        </h1>

        <i className="subtext">
          Fixing These Mistakes on Their Existing Websites With Our Business
          Boost Blueprint
        </i>
        <i className="help-text">
          Bonus: Get Your First 30 Days of Website Maintenance Free
        </i>
        <div className="subtext">
          Learn How We Can Help You Boost Website SEO Rankings, Increase Brand
          Awareness, Save <br /> Thousands on PPC, and Close More Deals
        </div>
        <PrimaryButton onClick={() => setShow(true)}>
          Get Free Instant Access Now!
        </PrimaryButton>
      </div>
      <div className="footer">© 2024 Innovative Mojo. All Rights Reserved.</div>
    </div>
  );
};

export default Engagement;
