import React from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Keyboard, Navigation, Pagination, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Cards.css";

// import stars from "../../assets/stars.png";
import stars from "../../../assets/newpage/stars.png";
import moveprevbtn from "../../../assets/newpage/moveBack.svg";
import movenextbtn from "../../../assets/newpage/moveNext.svg";

const CardsSlider = () => {
  const swiper = useSwiper();

  const handleprevbtn = () => {
    swiper?.slidePrev();
    console.log("slidePrev");
  };

  const handleNextvbtn = () => {
    swiper?.slideNext();
    console.log("slideNext");
  };

  return (
    <div className="cards-mob">
      <div className="cards-swiper-main-div">
        <img
          className="cards-custom-prev"
          onClick={handleprevbtn}
          src={moveprevbtn}
          alt="moveprevbtn"
        />

        <Swiper
          style={{
            "--swiper-pagination-color": "#8E7102",
            "--swiper-pagination-bullet-inactive-color": "#D9D9D9",
          }}
          slidesPerView={"auto"}
          speed={1000}
          initialSlide={1}
          centeredSlides={true}
          loop={true}
        pagination={{ clickable: true }}

          navigation={{
            nextEl: ".cards-custom-next",
            prevEl: ".cards-custom-prev",
          }}
          pagination={true}
          modules={[Keyboard, Navigation, Scrollbar, Pagination]}
          className="cards-swiper"
        >
          <SwiperSlide>
            <div className="mob-box-1 d-flex">
              <div className="">
                <img className="stars" src={stars} alt="" />
                <h1 className="box-heading my-3 ">
                  Innovative MOJO Became My Beacon Of Hope
                </h1>
                <p className="box-para para mb-4">
                  “Innovative MOJO became my beacon of hope after a disastrous
                  first attempt at getting my website done. Their
                  professionalism, quality, and rapid response have not only
                  restored my faith but truly made them the BEST!”
                </p>
                <h2 className="box-name">- Tiffany Murray</h2>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="mob-box-1 d-flex">
              <div className="">
                <img className="stars" src={stars} alt="" />
                <h1 className="box-heading my-3">
                  Truly Rewarding Partner - Absolutely Recommend
                </h1>
                <p className="box-para para mb-4">
                  “The team at Innovative MOJO possesses that rare blend of
                  speed and quality that has made them a truly rewarding
                  partner. Absolutely recommend”
                </p>
                <h2 className="box-name">- Alexi Knight </h2>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="mob-box-1 d-flex">
              <div className="">
                <img className="stars" src={stars} alt="" />
                <h1 className="box-heading my-3">
                  Delivering Top-Notch Designs!
                </h1>
                <p className="box-para para mb-4">
                  “Handling my high standards with grace and delivering
                  top-notch designs – that’s what MOJO is all about. Looking
                  forward to a long-lasting relationship!
                </p>
                <h2 className="box-name">- Daniel Ortiz </h2>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="mob-box-1 d-flex">
              <div className="">
                <img className="stars" src={stars} alt="" />
                <h1 className="box-heading my-3">
                  Earns Praise For Professionalism And High-End Feel
                </h1>
                <p className="box-para para mb-4">
                  “They promised and over-delivered a website that consistently
                  earns us praise for its professionalism and high-end feel!
                </p>
                <h2 className="box-name">- Mayra Halliday</h2>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <img
          className="cards-custom-next"
          onClick={handleNextvbtn}
          src={movenextbtn}
          alt="movenextbtn"
        />
      </div>
    </div>
  );
};

export default CardsSlider;
