import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDF7tahUaWBGtXfrqyQLjAKM_NNktntT48",
  authDomain: "innovative-mojo-n.firebaseapp.com",
  projectId: "innovative-mojo-n",
  storageBucket: "innovative-mojo-n.appspot.com",
  messagingSenderId: "257525653237",
  appId: "1:257525653237:web:62ce2298bbb0002e74efbc",
};

require("firebase/functions");

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

export const logOutFunction = async () => {
  await auth.signOut();
};

export default firebase;
export { firebaseConfig };
