import React from "react";
import ReactTyped from "react-typed";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import person1 from "../../assets/portfolio/logos/bulldog-logo.svg";
import person2 from "../../assets/portfolio/logos/foodie.svg";
import person3 from "../../assets/testimonial/person3.svg";
import BackgroundEffect from "../background-effect/background-effect.component";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "./testimonial-section.styles.scss";
const testimonials = [
  {
    review: 4,
    image: person1,
    title: "We Made RV Parks Cool.",
  },
  {
    review: 3,
    image: person3,
    title: "We Helped Streamline Cyber Security",
  },
  {
    review: 5,
    image: person2,
    title: "We told a creator's story through Food.",
  },
];
const TestimonialSection = () => {
  return (
    <div className="testimonial-section-component">
      <BackgroundEffect />
      <div className="container row testimonial-container">
        <Swiper
          effect={"fade"}
          modules={[Navigation]}
          speed={1400}
          navigation={true}
        >
          {testimonials.map((test, i) => (
            <SwiperSlide key={i}>
              <div className="testimonial-container">
                <div className="testimonial-image">
                  <img src={test.image} alt="" />
                </div>
                <div className="testimonial-content">
                  <div className="testimonial-title">
                    <q>
                      <ReactTyped strings={[test.title]} typeSpeed={100} loop />
                    </q>
                  </div>

                  {Array(5)
                    .fill()
                    .map((item, index) => (
                      <span
                        className={i + 1 < test.review ? "checked" : ""}
                        key={index}
                      ></span>
                    ))}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default TestimonialSection;
