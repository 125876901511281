import React from "react";
import Navbarr from "../navbar/navbar.component";

import arrow from "../../assets/arrow.png";

import "./small-banner.styles.scss";
const SmallBanner = ({ title, subtext, heading, title2 }) => {
  const scrollToNextSection = () => {
    const scrollHeight = window.innerHeight * 0.6;

    window.scrollTo(0, scrollHeight);
  };
  return (
    <div className="small-banner-component">
      <Navbarr />
      <div className="banner-content-container container">
        <div className="section1-content">
          <h1>{title}</h1>
          {title2 && <h1>{title2}</h1>}
          {(subtext || heading) && <br />}
          {subtext && <p>{subtext}</p>}
          {heading && <p>{heading}</p>}
        </div>
        <img src={arrow} alt="" onClick={scrollToNextSection} />
      </div>
    </div>
  );
};

export default SmallBanner;
