import React from "react";
import "./vsl-section13.styles.scss";
import FAQ from "../../../../components/faq/faq.component";
const faq = [
  {
    question: "How long until I'll see results?",
    answer:
      "We specialize in React web development, mobile application development, and Search Engine Optimization (SEO).",
  },
  {
    question: "Do I own the website and graphics?",
    answer:
      "Innovative MOJO was founded in 2018. Our seasoned team of developers has a collective experience of over 20 years..",
  },
  {
    question: "What technologies do you use? ",
    answer:
      "While each project is customized to meet your specific needs, typical timelines are as follows:",
    line2: "React Web Design: Approximately 45 days",
    line3: "Mobile Applications: Around 60 days",
    line4: "SEO: Generally 3 months",
  },
];
const VslSection13 = () => {
  return (
    <div className="section13-component">
      <div className="container">
        <div className="subtext">STILL HAVE QUESTIONS?</div>
        <h1>FREQUENTLY ASKED QUESTIONS</h1>
        <div className="subtext gradient">
          Get More Engagement And Brand Awareness Without You Lifting A Finger
        </div>
        <FAQ faq={faq} />
      </div>
    </div>
  );
};

export default VslSection13;
