import React from "react";

import tick from "../../../../assets/tick.png";
import image1 from "../../../../assets/section7.1.svg";
import image2 from "../../../../assets/section7.2.svg";
import image3 from "../../../../assets/section7.3.svg";

import "./vsl-section6.styles.scss";
import PrimaryButton from "../../../../components/button-primary/button-primary.component";
import { useNavigate } from "react-router-dom";
const section6Data = [
  {
    image: image1,
    title: "FULL SERVICE COMMITMENT",
    pointers: ["24/7 Support", "See Results, FAST"],
    description:
      "Our service doesn’t end at project completion. We’ll stand by you for the next 30 days to ensure everything is running smoothly.",
  },
  {
    image: image2,
    title: "CUSTOM WEBSITE TAILORED TO YOUR GOALS",
    pointers: ["Web Development", "Tech Expertise"],
    description: " We’ve developed a method of building that yields success.",
  },
  {
    image: image3,
    title: "SEO OPTIMIZATION THAT SOARS SITE RANKING PAST YOUR COMPETITION ",
    pointers: ["Boosts Your Organic Rankings", "Brings Down Goggle PPC Costs"],
    description:
      "We’ve developed proprietary tools to achieve desktop and mobile rankings for our website platform.",
  },
];
const VslSection6 = () => {
  const navigate = useNavigate();
  return (
    <div className="section6-component">
      <div className="container">
        <div className="heading-container">
          <div className="subtext">HOW IT WORKS</div>
          <h1>
            WE PROVIDE VALUE, SOLUTION, AND
            <span> DESIGN TO YOUR BUSINESS</span>
          </h1>
          <div className="subtext">
            Get More Engagement And Brand Awareness Without You Lifting A Finger
          </div>
        </div>
        <div className="section6-content-container">
          {section6Data.map((content, index) => (
            <div className="services-container" key={index}>
              <img className="service-image" src={content.image} alt="" />
              <div className="service-description">
                <h1>{content.title}</h1>
                <div className="description">{content.description}</div>
                {content.pointers.map((pointer, i) => (
                  <div className="pointer" key={i}>
                    <img src={tick} alt="" /> {pointer}
                  </div>
                ))}
              </div>
            </div>
          ))}

          <PrimaryButton onClick={() => navigate("/booking")}>
            Get FREE Instant Access Now
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default VslSection6;
