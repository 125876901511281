import React, { useState } from "react";
import googleIcon from "../../assets/google.png";
import firebase from "../../firebase/firebase";
import logo from "../../assets/home/logo.svg";
import "./google-auth.styles.scss";
import BackgroundEffect from "../../components/background-effect/background-effect.component";
import PrimaryButton from "../../components/button-primary/button-primary.component";
import { useNavigate } from "react-router-dom";
const SignInUpWithGoogle = () => {
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState("");

  const googleAuth = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    try {
      const authResult = await firebase.auth().signInWithPopup(provider);
      // Get the signed-in user's email
      const user = authResult.user;
      const userEmail = user.email;

      // Check if the user's email exists in the Firestore collection
      const emailsCollection = firebase.firestore().collection("Admins");
      const querySnapshot = await emailsCollection.doc("Emails").get();
      console.log(querySnapshot);
      if (querySnapshot.exists) {
        const emailArray = querySnapshot.data().emails;
        if (emailArray.includes(userEmail)) {
          // User's email exists in the array, proceed with sign-in
          setErrMessage("");
        } else {
          // User's email is not in the array, show an error message
          setErrMessage("Email not authorized for sign-in.");
          await firebase.auth().signOut(); // Sign the user out
        }
      } else {
        // The 'emails' document does not exist in Firestore
        setErrMessage("Emails document not found.");
      }
    } catch (err) {
      console.log(err.message);
      console.log(err.message);
      if (err.code === "auth/account-exists-with-different-credential") {
        setErrMessage(
          "You have already registed with this email using Facebook. Please sign in using Facebook"
        );
      }
    }
  };
  return (
    <div className="page">
      <div className="google-container">
        <BackgroundEffect />
        <img onClick={() => navigate("/")} className="logo" src={logo} alt="" />

        <div className="google-auth">
          <div className="googleButtonParent" onClick={() => googleAuth()}>
            <PrimaryButton noarrow>
              <img src={googleIcon} alt="google-icon" /> &nbsp; Continue with
              Google
            </PrimaryButton>

            <p className="error-message">{errMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInUpWithGoogle;
