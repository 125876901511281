export const filterPages = (visiblePages, totalPages) => {
  return visiblePages.filter((page) => page <= totalPages);
};
export const getVisiblePages = (page, total) => {
  if (total < 7) {
    return filterPages([1, 2, 3, 4, 5, 6], total);
  } else {
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    } else {
      return [1, 2, 3, 4, 5, total];
    }
  }
};

export const getDateInYYYYMMDDFormat = (value, na) => {
  const date = new Date(value);
  const dayRaw = date.getDate();
  const day = dayRaw < 10 ? `0${dayRaw}` : dayRaw;
  const monthRaw = date.getMonth() + 1;
  const month = monthRaw < 10 ? `0${monthRaw}` : monthRaw;
  const year = date.getFullYear();

  if (na) return value ? `${year}/${month}/${day}` : "N/A";
  else return `${year}/${month}/${day}`;
};

export const paginationOptions = [
  { title: 5, value: 5 },
  { title: 15, value: 15 },
  { title: 25, value: 25 },
];
export function getRandomId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
