import React, { useEffect, useState } from "react";
import "./create-article.styles.scss";
import RichEditorComponent from "../../components/rich-text-editor/rich-text-editor.component";
import TextfieldAtomNew from "../../components/textfield-new/textfield-new.atom";
import TextareaAtomNew from "../../components/textarea-new/textarea-new.atom";
import ImagePreviewerAndEdit from "../../components/image-previewer-and-edit/image-previewer-and-edit.component";
import { functions } from "../../firebase/firebase";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/button-primary/button-primary.component";

const CreateArticle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editorContent, setEditorContent] = useState("This Is Example Text");
  const [id, setId] = useState("");
  const [updated, setupdated] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    summary: "",
    coverPhotoURL: "",
  });

  useEffect(() => {
    if (location.state?.article) {
      setFormData({
        title: location.state.article.title,
        summary: location.state.article.summary,
        coverPhotoURL: location.state.article.coverPhotoURL,
      });
      setId(location.state.article.id);
      setEditorContent(location.state.article.htmlContent);
    }
    setupdated(true);
  }, [location.state?.article]);

  const handleContentChange = (content) => {
    setEditorContent(content);
  };
  const handleChange = (e, name) => {
    const value = e;
    setFormData({ ...formData, [name]: value });
  };
  const handleFile = (file) => {
    setFormData({ ...formData, coverPhotoURL: file });
  };
  const handleSubmit = async () => {
    if (
      formData.title &&
      formData.summary &&
      formData.coverPhotoURL &&
      editorContent
    ) {
      const createArticleInDb = functions.httpsCallable("createArticleInDb");
      Swal.fire({
        title: `${id ? "Updating" : "Creating"} article`,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const resp = await createArticleInDb({
        ...formData,
        htmlContent: editorContent,
        id: id,
      });
      if (resp.data.success) {
        Swal.fire({
          icon: "success",
          title: `Article ${id ? "Updated" : "Created"}`,
          showCancelButton: false,
        }).then((resp) => {
          if (resp.isConfirmed || resp.isDismissed) {
            navigate("/admin-articles");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: resp.data.message,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all relative fields",
      });
    }
  };
  return (
    <div className="admin-article-creation">
      <div className="heading">
        <h3> {id ? "Update" : "Create"} an article</h3>

        <PrimaryButton noarrow onClick={handleSubmit}>
          Publish
        </PrimaryButton>
      </div>
      <div className="form-parent">
        <div className="form">
          <ImagePreviewerAndEdit
            square
            datatest="event-logo"
            id="cover"
            edit={true}
            file={formData.coverPhotoURL}
            setFile={handleFile}
          />
          <br />
          <TextfieldAtomNew
            required
            value={formData.title}
            label="Article Title"
            setValue={(e) => handleChange(e, "title")}
            characterCount={100}
          />
          <TextareaAtomNew
            characterCount={250}
            required
            value={formData.summary}
            label="Article Summary"
            setValue={(e) => handleChange(e, "summary")}
          />
          {/* <span className="cover-heading">Cover photo for Article</span> */}

          {updated && (
            <RichEditorComponent
              content={editorContent}
              onContentChange={handleContentChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateArticle;
