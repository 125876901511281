import React, { useEffect, useRef } from "react";
import TinyMCE from "react-tinymce/lib/components/TinyMCE";
function prependDataUrlPrefix(base64String) {
  let mimeType;

  if (base64String.startsWith("iVBORw0KG")) {
    mimeType = "image/png";
  } else if (base64String.startsWith("/9j/")) {
    mimeType = "image/jpeg";
  } else if (base64String.trim().startsWith("<svg")) {
    mimeType = "image/svg+xml";
  } else {
    return null; // or throw an error if the type is unknown
  }
  return `data:${mimeType};base64,${base64String}`;
}
const RichEditorComponent = ({ content, onContentChange }) => {
  const editorRef = useRef(null);

  const handleImageUpload = (blobInfo, success, failure) => {
    fetch(
      "https://us-central1-innovative-mojo-n.cloudfunctions.net/uploadImageForEditor",
      {
        method: "POST",
        body: JSON.stringify({
          image: prependDataUrlPrefix(blobInfo.base64()),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data.success) {
          success(data.url);
        } else {
          failure(data.message || "Failed to upload image.");
        }
      })
      .catch((error) => {
        failure("Failed to upload image: " + error.message);
      });
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(content);
    }
  }, [content]);
  return (
    <div>
      <TinyMCE
        content={content}
        config={{
          plugins: "autolink link image lists print preview",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright | image",
          height: 1000,

          images_upload_handler: handleImageUpload,
          paste_data_images: true,
        }}
        onChange={(e) => onContentChange(e.target.getContent())}
      />
    </div>
  );
};

export default RichEditorComponent;
