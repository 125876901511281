import arrow from "../../assets/home/arrow.svg";
import "./button-primary.styles.scss";

export default function PrimaryButton({ children, noarrow, ...props }) {
  return (
    <div className={"primary-button-component"}>
      <button {...props}>
        {children} {!noarrow && <img src={arrow} alt="" />}
      </button>
    </div>
  );
}
