import React from "react";
import "./background-effect.styles.scss";
const BackgroundEffect = ({ classes }) => {
  return (
    <div className={`background-effect-component ${classes}`}>
      <div className="effect"></div>
    </div>
  );
};

export default BackgroundEffect;
