import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/home/logo.svg";
import triangle from "../../assets/triangle.svg";
import PrimaryButton from "../../components/button-primary/button-primary.component";
import BackgroundEffect from "../../components/background-effect/background-effect.component";
import { Modal } from "react-bootstrap";
import "./business-boast.styles.scss";
import "./business-boast.styles.css";
const BusinessBoast = () => {
  const navigate = useNavigate();

  const [play, setPlay] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true); // Automatically show modal when page loads
  }, []);

  const handleClose = () => setShow(false);

  return (
    <>
    <div className="business-modal">
<Modal

        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        <h1>SCHEDULE YOUR CALL BELOW</h1>
        <Modal.Body className="business-modal">
          <iframe
            src="https://www.cnvrsnly.com/widget/booking/OpnkE1qxeFR7a6j4sEfB"
            style={{
              width: '100%',
              height:"100%",
             minHeight:"500px" ,
              border: 'none',
              overflow: 'hidden',
            }}
            scrolling="no"
            id="ZRiHYlv8tkHyGWxJUaXQ_1712351353153"
          ></iframe>
        </Modal.Body>
        <Modal.Footer className="business-modal">
          <PrimaryButton variant="secondary" onClick={handleClose}>
            Close
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
</div>
<div className="business-boast-component">



<BackgroundEffect classes="top" />
<div className="business-container">
  <img src={logo} alt="" className="logo" onClick={() => navigate("/")} />
  <div className="engagement-content-container">
    <div className="subtext">
      Let Innovative Mojo Design Your Business’s Digital Future, Today!
    </div>
    <h1>
      Are You Ready to Unlock the
      <span> Business Boost Blueprint?!</span>
    </h1>
    <h1 className="ilatic">
      <i>in Extra Revenue in 2 Months </i>
    </h1>

    <div className="subtext">
      Our Team Of Developers Will Create Unique, Powerful Websites That
      Will Showcase Your <br /> Business In The Best Light
    </div>

    <div className="video-container">
      {!play ? (
        <div className="play-container" onClick={() => setPlay(!play)}>
          <img src={triangle} alt="" />
        </div>
      ) : (
        <iframe
          src="https://www.youtube.com/embed/Q_NJOa1FR2g?autoplay=1&amp;rel=0&amp;controls=1"
          allow="autoplay"
          title="player"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      )}
    </div>
    <PrimaryButton onClick={() => setShow(true)}>Get Free Instant Access Now!</PrimaryButton>
  </div>
  <div className="footer">
    © 2024 Innovative Mojo. All Rights Reserved.
  </div>
</div>
<BackgroundEffect classes="bottom" />
</div>
    </>
   
  );
};

export default BusinessBoast;
