import React from "react";
import PrimaryButton from "../../../../components/button-primary/button-primary.component";
import { useNavigate } from "react-router-dom";
import "./vsl-section4.styles.scss";
const VslSection4 = () => {
  const navigate = useNavigate();
  return (
    <div className="section4-component ">
      <div className="container">
        <div className="subtext">
          WANT TO 50X YOUR BUSINESS’S ONLINE VISIBILITY? INNOVATIVE MOJO IS YOUR
          DIGITAL SOLUTION!
        </div>
        <h1 className="center">
          SPEND MORE TIME GROWING YOUR BUSINESS AND LESS TIME
          <span> MANAGING YOUR WEBSITE</span>
        </h1>
        <div className="subtext">
          Ready To Scale Your Business Into The Digital Age!? We Have The
          BluePrint!
          <span>LET'S TACKLE YOUR ONLINE CHALLENGES</span>
        </div>
        <PrimaryButton onClick={() => navigate("/booking")}>
          Get 30 Days of FREE Website Maintenance
        </PrimaryButton>
      </div>
      <div className="stat-content-containers">
        <div className="section-content-container">
          <h1>
            <span>20+</span>
          </h1>
          YEARS EXPERIENCE
        </div>
        <div className="section-content-container">
          <h1>
            <span>700+</span>
          </h1>
          HAPPY CLIENTS
        </div>
        <div className="section-content-container">
          <h1>
            <span>1200</span>
          </h1>
          SUCCESSFUL PROJECTS
        </div>
      </div>
    </div>
  );
};

export default VslSection4;
