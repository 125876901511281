import React, { useEffect, useState } from "react";
import "./admin-article-page.styles.scss";
import { useNavigate } from "react-router-dom";
import { firestore, functions } from "../../firebase/firebase";
import ReactTableComponent from "../../components/new-table/react-table-new.component";
import ArticleFeatureModal from "../../components/featured-articles-modal/featured-articles-modal.component";
import { ReactComponent as EditSvg } from "../../assets/edit.svg";
import { ReactComponent as DeleteSvg } from "../../assets/delete.svg";
import Swal from "sweetalert2";
import PrimaryButton from "../../components/button-primary/button-primary.component";

export const getDateInYYYYMMDDFormat = (value, na) => {
  const date = new Date(value);
  const dayRaw = date.getDate();
  const day = dayRaw < 10 ? `0${dayRaw}` : dayRaw;
  const monthRaw = date.getMonth() + 1;
  const month = monthRaw < 10 ? `0${monthRaw}` : monthRaw;
  const year = date.getFullYear();

  if (na) return value ? `${year}/${month}/${day}` : "N/A";
  else return `${year}/${month}/${day}`;
};

const AdminArticlePage = () => {
  const [articles, setArticles] = useState([]);
  const [columns, setColumns] = useState([]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("/admin-articles/create");
  };

  useEffect(() => {
    const snapShot = firestore
      .collection("Articles")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        const articlesFromDb = snapshot.docs.map((doc) => {
          const data = doc.data();

          const articleData = {
            ...data,
            title: data.title,
            summary: data.summary,
            createdAt: getDateInYYYYMMDDFormat(data.timestamp),
            id: doc.id,
          };

          return articleData;
        });
        setArticles(articlesFromDb);
      });
    return () => snapShot();
  }, []);

  const deletedArticle = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin();
    swalWithBootstrapButtons
      .fire({
        title: "Delete An Article?",
        html: `Are you sure you want to Delete this article?`,
        confirmButtonText: `Yes`,
        confirmButtonColor: "orange",
        showCancelButton: true,
        allowOutsideClick: false,
        cancelButtonText: `No`,
        cancelButtonColor: "#D3D2D2",
        allowEscapeKey: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "info",
            title: "Deleting An Article",
            didOpen: () => {
              Swal.showLoading();
            },
            showCancelButton: false,
          });
          const deleteArticle = functions.httpsCallable("deleteArticle");
          const resp = await deleteArticle({ id: id });
          if (resp.data.success) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Successfully deleted Article",
              showCancelButton: false,
            });
          }
        }
      });
  };

  React.useEffect(() => {
    const columns = [
      {
        Header: "Users",
        columns: [
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Summary",
            accessor: "summary",
          },

          {
            Header: "Featured",
            Cell: (row) => {
              const index = row.row.index;
              const article = row.data[index];
              return article.isFeatured ? "Yes" : "No";
            },
          },
          {
            Header: "Created On",
            accessor: "createdAt",
          },
          {
            Header: "Edit",
            Cell: (row) => {
              const index = row.row.index;
              const article = row.data[index];
              return (
                <EditSvg
                  className="edit-icon"
                  onClick={() => {
                    navigate("/admin-articles/create", {
                      state: {
                        article,
                      },
                    });
                  }}
                />
              );
            },
          },
          {
            Header: "Delete",
            Cell: (row) => {
              const index = row.row.index;
              const article = row.data[index];
              return (
                <DeleteSvg
                  onClick={() => {
                    deletedArticle(article.id);
                  }}
                />
              );
            },
          },
        ],
      },
    ];

    setColumns(columns);
  }, [navigate]);

  return (
    <div className="admin-article">
      <div className="create">
        <PrimaryButton noarrow onClick={() => setShow(true)}>
          Feature Articles
        </PrimaryButton>
        <PrimaryButton noarrow onClick={handleCreate}>
          Create Article
        </PrimaryButton>
      </div>

      <ReactTableComponent
        columns={columns}
        data={articles}
        loading={!articles.length}
        pagination
      />
      <ArticleFeatureModal
        show={show}
        onHide={() => setShow(!show)}
        articles={articles}
      />
    </div>
  );
};

export default AdminArticlePage;
