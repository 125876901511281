import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper/modules";

import BannerSection from "../banner-section/banner-section.component";
import AboutSection from "../about-section/about-section.component";
import ServicesSection from "../services-section/services-section.component";
import BenefitsSection from "../benefits-section/benefits-section.component";
import TestimonialSection from "../testimonial-section/testimonial-section.component";
import FAQ from "../faq/faq.component";
import HomeFooter from "../home-footer/home-footer.component";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const HomeWrapper = () => {
  const [currentSlide, setCurrentSlide] = useState(0); // State to store current slide number

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  return (
    <Swiper
      onSlideChange={handleSlideChange}
      modules={[Pagination, Mousewheel]}
      direction="vertical"
      speed={1400}
      pagination={{ clickable: true }}
      mousewheel={{
        forceToAxis: true,
        releaseOnEdges: true,
        sensitivity: 0.2,
        threshold: 20,
      }}
    >
      <SwiperSlide>
        <BannerSection />
      </SwiperSlide>
      <SwiperSlide>
        <AboutSection currentSlide={currentSlide} />
      </SwiperSlide>
      <SwiperSlide>
        <ServicesSection />
      </SwiperSlide>
      <SwiperSlide>
        <BenefitsSection />
      </SwiperSlide>
      <SwiperSlide>
        <TestimonialSection />
      </SwiperSlide>
      <SwiperSlide>
        <FAQ />
      </SwiperSlide>
      <SwiperSlide>
        <HomeFooter />
      </SwiperSlide>
    </Swiper>
  );
};

export default HomeWrapper;
