import React from "react";
import ImportantFieldAsterik from "../important-field-asterik/important-field-asterik.atom";

import "./textarea-new.styles.scss";

const TextareaAtomNew = ({
  value,
  setValue,
  label,
  title,
  hideOnFocus,
  setError,
  error,
  regularExpression,
  errorMessage,
  placeholder,
  min,
  required,
  noBorder,
  white,
  characterCount,
  help,
  ...props
}) => {
  const [focus, setFocus] = React.useState(false);
  const [limit, setLimit] = React.useState(false);

  React.useEffect(() => {
    if (value) {
      setFocus(true);
    }
  }, [value]);

  const handleFocus = (e) => {
    setFocus(true);
  };

  const looseFocus = (e) => {
    if (!value) {
      setFocus(false);
    }
  };
  const handleChange = (e) => {
    if (!setValue) return;
    e.stopPropagation();
    const inputValue = e.target.value;
    let newValue = inputValue;

    if (characterCount) {
      const inputLength = inputValue.length;
      if (inputLength > characterCount) {
        newValue = inputValue.substring(0, characterCount);
      }
      setLimit(inputLength >= characterCount);
    }

    setValue(newValue);

    let errorCount = 0;
    if (required && newValue.trim() === "") {
      errorCount++;
    }
    if (setError) setError(errorCount > 0);
  };

  return (
    <div
      onClick={handleFocus}
      className={`textarea-atom-new ${noBorder ? "noBorder" : ""} ${
        white ? "white" : ""
      }`}
    >
      <label className={`${focus ? "focus" : ""} ${hideOnFocus ? "hide" : ""}`}>
        {label} {required && <ImportantFieldAsterik />}
      </label>
      <textarea
        className={`${
          error ? "red-border" : min && error === false ? "green-border" : ""
        } `}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={looseFocus}
        onFocus={handleFocus}
        {...props}
      ></textarea>
      {characterCount ? (
        <div className={`error ${!error ? "no-error" : ""}`}>
          <span
            className={`character-count ${
              limit ? "bold" : error ? "error-red" : "normal"
            }`}
          >
            {min ? `Minimum ${min} to ` : ""}
            Maximum {characterCount} characters ({value.length}/{characterCount}
            )
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TextareaAtomNew;
