import React from "react";
import star from "../../../../assets/star.png";
import "./vsl-section15.styles.scss";
import "../../vsl.styles.scss";

const returnImages = () => {
  return (
    <div className="rating-container">
      <img src={star} alt="" />
      <img src={star} alt="" />
      <img src={star} alt="" />
      <img src={star} alt="" />
      <img src={star} alt="" />
    </div>
  );
};
const VslSection15 = () => {
  return (
    <div className="section15-component">
      <div className="container">
        <h1>YOU COULD BE OUR NEXT SUCCESS STORY!</h1>

        <div className="section-content">
          <div className="section15-content-container">
            <div className="content">
              {returnImages()}
              <h2>A Startup's Rave Review for Eric and Team!</h2>
              <div className="description">
                I am a new start up business and can’t say enough on how amazing
                they are. My representative, Eric, was super knowledgeable and
                patient. He was very responsive, understood my business and what
                I was looking for, always offered great suggestions and
                feedback, and always willing to go above and beyond. Overall the
                service is amazing and I would definitely recommend them.
              </div>
              <h3>Sugein Herrera</h3>
            </div>

            <div className="content">
              {returnImages()}
              <h2>Seamless Excellence: Innovative MOJO Leads the Way</h2>
              <div className="description">
                Very professional team and attentive to my exact vision. Not
                only did I receive what I asked for, the team actually went
                above & beyond my expectations with my website. When I had
                questions, they were there to guide me in the right direction.
                <br />
                If your looking for a professional efficient team, you found it!
              </div>
              <h3>Manuela Mora</h3>
            </div>
          </div>

          <div className="section15-content-container">
            <div className="content">
              {returnImages()}
              <h2>MOJO: Turning Skepticism Into Reality</h2>
              <div className="description">
                I was a new small business owner struggling to get clientele
                when a friend referred me to MOJO. I was a bit skeptical at
                first, but decided to reach out to them because it really
                couldn’t get much worse. I spoke with Santiago and immediately
                clicked with him as he was very professional, down to earth,
                easy to talk to, knowledgeable and had some very innovative
                ideas. I decided to give MOJO a try and after a few months I’ve
                had to hire four new employees to help with all the work I’m
                accumulating. I highly recommend them!
              </div>
              <h3>Carlos Fortin</h3>
            </div>
            <div className="content">
              {returnImages()}
              <h2>Embracing Success with Innovative Mojo Website Design</h2>
              <div className="description">
                Working with Innovative Mojo Website Design was a pleasure. I
                couldn’t be happier with the turnout. It was a very enjoyable
                experience working with the team throughout the project. They
                had answers for all the questions I threw their way and just
                made things happen. I cant recommend them enough!
              </div>
              <h3>Jose Portillo</h3>
            </div>
          </div>


          <div className="section15-content-container">
            <div className="content">
              {returnImages()}
              <h2>Innovative MOJO's Exceptional Website Mastery</h2>
              <div className="description">
              Innovative MOJO team are amazing, we needed a website done in record time and they work hard to accomplished it and not just that, they exceeded our expectations. We always get complements about how professional and high end our website is.
I highly recommend Innovative MOJO company, they would work hard for you to bring your dreamed website to live.

              </div>
              <h3>Mayra Halliday</h3>
            </div>
            <div className="content">
              {returnImages()}
              <h2>Mojo's Timely and Exceptional Website Development</h2>
              <div className="description">
              Mojo has developed several websites for me and my clients and have exceeded my expectations and always delivered ahead of schedule.

              </div>
              <h3>Rhonda</h3>
            </div>
          </div>
        </div>
        <hr />
        <div className="footer-text">
          Copyright 2024 . Innovative Mojo . All rights reserved. Privacy Policy
        </div>
      </div>
    </div>
  );
};

export default VslSection15;
