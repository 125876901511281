import React from "react";
import "./faq-item.styles.scss";

const FAQItem = ({ item, isOpen, toggle, index }) => {
  const { question, answer, line2, line3, line4 } = item;
  const checkIsOpen = () => {
    if (index === isOpen.index && isOpen.status) return true;
    else return false;
  };
  const handleClick = () => {
    const status = checkIsOpen() ? false : true;
    toggle(index, status);
  };
  return (
    <div
      className={`faq-item-component ${checkIsOpen() ? "open" : ""}`}
      onClick={handleClick}
    >
      <div className="question">
        <span className="question-text">{question}</span>
        <span className="icon">{checkIsOpen() ? "-" : "+"}</span>
      </div>
      <div className="answer">
        <p>{answer}</p>
        <div className="small">
          {line2 && <li>{line2}</li>}
          {line3 && <li>{line3}</li>}
          {line4 && <li>{line4}</li>}
        </div>
        <p></p>
      </div>
    </div>
  );
};

export default FAQItem;
