import React, { useState, useEffect } from "react";
import logo from "../../assets/home/logo.svg";
import "./admin-menu-bar.styles.scss";

import { logOutFunction } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../button-primary/button-primary.component";

const AdminMenuBar = () => {
  const [activeCategory, setActiveCategory] = useState(-1);
  const navigate = useNavigate();
  useEffect(() => {
    const path = window.location.pathname.split("/")[1];
    setActiveCategory(path);
  }, []);

  const signOutClickHandler = async () => {
    await logOutFunction();
  };

  return (
    <div className="admin-menubar-component">
      <div className="admin-menubar-content-container">
        <div className="logo-and-menu-container">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="menu-bar-logo-component"
          >
            <img src={logo} alt="" loading="lazy" />
          </div>
          <div className={`menu-list-container`}>
            <span>Dashboard </span>

            <span
              onClick={() => {
                navigate("/admin-articles");
              }}
              className={`${
                activeCategory === "admin-articles" ? "active" : ""
              }`}
            >
              Manage Articles
            </span>
            <span>Users </span>
          </div>
        </div>
        <div className="logout-btn">
          <PrimaryButton onClick={() => signOutClickHandler()} type="button">
            Sign Out
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AdminMenuBar;
