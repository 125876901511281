import React, { useState } from "react";
import tick from "../../../../assets/tick.png";
import foodie from "../../../../assets/portfolio/logos/foodie.svg";
import "./vsl-section7.styles.scss";
const VslSection7 = () => {
  const [video, setVideo] = useState(false);
  return (
    <div className="section7-component">
      <div className="container">
        <h1>CHECK OUT OUR RECIPE TO THIS FOODIES CULINARY TRIUMPH!</h1>
        <br />
        <div className="section-content-container">
          <div className="logo-with-content">
            <a target="__blank" href="https://foodienotachef.com/">
              <img src={foodie} alt="" />
            </a>

            <div>
              <h2>
                <a target="__blank" href="https://foodienotachef.com/">
                  FOODIENOTACHEF.COM{" "}
                </a>
              </h2>
              Tiffany is a culinary enthusiast who had a strong Instagram
              following but lacked a dedicated platform to comprehensively
              showcase her recipes and generate revenue. She sought out
              Innovative Mojo to build her online brand, drive more attention,
              and create additional streams of income.
            </div>
          </div>
        </div>
        <div className="section-content-container">
          <h2>THE PROCESS</h2>
          <div className="process-description">
            Innovative Mojo undertook the following steps to meet Tiffany's
            needs. By focusing on these key areas, Innovative Mojo created a
            comprehensive online platform that effectively met Tiffany's
            objectives.
            <div className="process-pointers">
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Conducted an in-depth analysis to understand the target
                  audience and their engagement with culinary content.
                </div>
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Designed a user-friendly website focusing on optimal user
                  experience and ease of navigation.
                </div>
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Added a blog section dedicated to Tiffany's diverse recipes
                  and culinary tips.
                </div>
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Developed a structured, simplified recipe format to facilitate
                  user engagement.
                </div>
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Incorporated referral and sponsored content options to
                  generate additional revenue streams.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-content-container">
          <div className="results-container">
            <div className="result-description">
              <h2>THE RESULTS</h2>
              The new website had an immediate impact: Gained 3 new sponsors
              within 4 months. <br /> <br />
              ● Enhanced brand awareness  <br />
              ● Extended reach beyond her Instagram audience. <br /> <br />
              Tiffany's collaboration with Innovative Mojo has not only given
              her a new platform to express her culinary creativity but also
              significantly contributed to her brand's financial growth.
            </div>
            <div className="video-thumbnail">
              {/* {video ? (
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/k8Bcj_982Z0?si=QhMAkYqLt4SaxL-u&amp;controls=0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              ) : (
                <img
                  onClick={() => setVideo(true)}
                  className="video-thumbnail"
                  src="https://s3-alpha-sig.figma.com/img/f39a/419d/8ab13258ec5e04011a6c4e5e7c1bf145?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=e8shmVA7p8gRxQk77iZiLPqZkesVpnADlD8HBRsBuZpHi-dJdcbHA8uDY9zS16rAHhxacPoIvisc2yonE-hxC-W-O02RPt21pxts~7rZ-UUSx--2XfCFY-eiPJML2JziZN9i9VQx8tRYZTZszjHq4Fouqe84BxS7jBofxy~oWMkLVW9qf4xRM4ooO8nYY865W5SQwQO1do8zp7zNjkjPbGIpMF6hJrbM6j5DTJnj7f1wxTlA4Ij0QpipX0V5w5vtnI1pCfxSKNViIpyQB2DYA6vWLPWd9W2j-5~sxirSPb6PKnDXt2P7ytkkIjB-NRGbWwroWlpY8kkM6OYSi2ez1A__"
                  alt=""
                />
              )} */}
               <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/k8Bcj_982Z0?si=QhMAkYqLt4SaxL-u&amp;controls=0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VslSection7;
