import { useEffect, useState } from "react";
import firebase from "../firebase/firebase";
import { useNavigate } from "react-router-dom/dist";

const useAuth = (allowedRoutes) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        setUser(userAuth);
      } else {
        setUser("");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user === null) {
      return;
    }

    if (!user && !allowedRoutes.includes(window.location.pathname)) {
      // navigate("/");
    } else if (user && window.location.pathname === "/login") {
      navigate("/admin-articles");
    }
  }, [user, navigate, allowedRoutes]);

  return user;
};

export default useAuth;
