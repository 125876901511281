import React from "react";
import check from "../../../../assets/check.svg";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/button-primary/button-primary.component";
import "./vsl-section14.styles.scss";
const VslSection14 = () => {
  const navigate = useNavigate();
  return (
    <div className="section14-component">
      <div className="container">
        <div className="section5-headings">
          <div className="subtext">WHY US?</div>
          <h1>
            AT INNOVATIVE MOJO, WE BRING MORE THAN 20 YEARS OF EXPERIENCE TO THE
            TABLE
          </h1>
          <div className="subtext">
            but we believe that the cornerstone of our success lies in real
            conversations, with real people, tackling real challenges using our
            proven 6 step process:
          </div>
          <PrimaryButton onClick={() => navigate("/booking")}>
            <div className="button-flex">
              <h5>Act Now and Receive</h5>
              <div>30 Days of FREE Maintenance</div>
            </div>
          </PrimaryButton>
        </div>
        <div className="section5-description">
          <div className="subtext-large">
            Ready To Scale Your Digital Business? Discover The Business Boost
            Blueprint!
          </div>
          <ul>
            <li>
              <img src={check} alt="" />
              Discovery & Consultation
            </li>
            <li>
              <img src={check} alt="" />
              Research & Analysis
            </li>
            <li>
              <img src={check} alt="" />
              Planning & Strategy
            </li>
            <li>
              <img src={check} alt="" />
              Design & Development
            </li>
            <li>
              <img src={check} alt="" />
              Testing & Quality Assurance
            </li>
            <li>
              <img src={check} alt="" />
              Launch and Beyond
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VslSection14;
