import "./mobile-features.styles.scss";
import feature1 from "../../assets/feature1.png";

export default function MobileFeatures({ features }) {
  return (
    <div className="mobile-features-component container">
      <div className="all-features row">
        {features.map((item, index) => (
          <div key={index} className="feature-container ">
            {item.icon}
            <h4>{item.title}</h4>
            <p className="subtext">{item.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
