import styled from "styled-components";

export const NewTableStyles = styled.div`
  .loadingTable {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }
  .table-parent {
    margin-top: 20px;
    max-width: 100%;

    .table-overflow-container {
      outline: 1.5px solid #d3d2d2;
      border-radius: 8px;
      margin-bottom: 1rem;
      max-width: 100% !important;
      overflow-x: none;
      padding: 1px;

      &::-webkit-scrollbar {
        width: 3px;
        height: 12px;
        padding: 10px;
        border-radius: 50px;
      }
      i {
        margin-left: 10px;
        color: var(--secondary-color) !important;
        font-size: 15px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #edeff2;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d3d2d2;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #d3d2d2;
      }
    }

    table {
      margin: 0;
      border-spacing: 0;

      border-collapse: separate;
      font-size: 12px;
    }

    tr {
      td {
        max-width: 20% !important;
        border: 0;
        vertical-align: middle !important;
      }
    }
    thead {
      background: #f1f2f2;
      margin: 20px;

      tr {
        &:first-of-type {
          display: none;
        }
        &:nth-child(2) {
          border-radius: 8px !important;
          position: sticky;

          th {
            background: #f9fafa !important;
            border-bottom-style: none;
            white-space: nowrap;
            border: 0;
          }
        }
      }
    }
    tbody {
      tr:nth-child(even) {
        background: red;
      }
    }

    .pagination {
      justify-content: flex-end;
      .pagination-dropdown {
        .dropdown {
          margin-left: 2em;
          &.show {
            .btn {
              border: 2px solid var(--primary-color);
              border-radius: 9px;
            }
          }
          .btn {
            /* Accent/ Charcoal shade 5 */
            border: 1px solid #d3d2d2;
            border-radius: 9px;
            color: #7b7979;
            height: 40px;

            &::after {
              display: none;
            }
          }
          &.Search {
            .btn {
              background: #f7f7f7;
            }
          }

          .dropdown-menu {
            &.show {
              border-radius: 18px;
            }
            .dropdown-item {
              .dropDown-options {
                display: flex;
              }
            }
          }
        }
      }
      .pagination-btns {
        display: flex;
        align-items: center;

        > * {
          margin: 0 0 0 20px;
        }
        img {
          cursor: pointer;
          width: 13px;
          height: 20px;
        }

        .pageCount {
          display: flex;
          > * {
            margin: 0 0 0 9px;
          }
          .pageNumbers {
            padding: 0.2em 0.6em;
            color: #8c9496;
            cursor: pointer;
          }
          .active {
            background: #f1f2f2;
            border-radius: 6px;
            color: black;
          }
          .endpages {
            display: flex;
            align-items: center;
            p {
              margin: 0;
              color: #8c9496;
            }
            a {
              padding: 0.2em 0.6em;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;
