import React from "react";
import PrimaryButton from "../../../../components/button-primary/button-primary.component";
import { Link } from 'react-router-dom';
import "./vsl-section8.styles.scss";

const VslSection8 = ({ setShow }) => {
  const handleButtonClick = () => {
    setShow(true);
  };

  return (
    <div className="section8-component">
      <div className="container">
        <div className="section5-headings-container">
          <div className="subtext">
            Are You Ready To Unlock Your Website’s Full Potential?
          </div>
          <h2>
            WE SPECIALIZE IN DIGITAL MODERNIZATION TO GROW YOUR ONLINE PRESENCE
          </h2>
          <Link to="/vsl">
          <PrimaryButton >
            <div className="button-flex">
              <h5>Get Your FREE 30 Days</h5>
              <div>OF WEBSITE MAINTENANCE FOR FREE</div>
            </div>
          </PrimaryButton>
          </Link>
          {/* <PrimaryButton onClick={handleButtonClick}>
            <div className="button-flex">
              <h5>Get Your FREE 30 Days</h5>
              <div>OF WEBSITE MAINTENANCE FOR FREE</div>
            </div>
          </PrimaryButton> */}
        </div>
        <div className="section5-headings-container">
          <div className="subtext">
            Learn How We Can Help Boost Your Brand Awareness
          </div>
          <h2>
            WE OFFER CUSTOM WEB SOLUTIONS THROUGH OUR TECH EXPERTISE TO INCREASE
            BRAND RECOGNITION
          </h2>
        <Link to="/vsl">
        <PrimaryButton >
            <div className="button-flex">
              <h5>BOOK A 30 MINUTE DEMO</h5>
              <div>WITH ONE OF OUR PRODUCTS SPECIALIST</div>
            </div>
          </PrimaryButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VslSection8;
