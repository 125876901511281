import React from "react";
import tick from "../../../../assets/tick.png";
import quote from "../../../../assets/quote.png";
import iviry from "../../../../assets/portfolio/logos/iviry.svg";
import "./vsl-section10.styles.scss";
const VslSection10 = () => {
  return (
    <div className="section10-component">
      <div className="container">
        <h1>
          CRUISE THROUGH THE COAST OF IVIRY AND TAKE IN THE VIEWS OF OUR
          SUCCESSFUL COLLABORATION 
        </h1>
        <br />
        <div className="section-content-container">
          <div className="logo-with-content">
            <a target="__blank" href="https://iviry.com/">
              <img src={iviry} alt="" />
            </a>
            <div>
              <h2>
                <a target="__blank" href="https://iviry.com/">
                  IVIRY{" "}
                </a>
              </h2>
              <a target="__blank" href="https://iviry.com/">
                Iviry
              </a>
              &nbsp;is a tech-focused company that developed software for IT
              specialists, designed to help them manage and maintain IT services
              for their clients. While the software was sophisticated and
              functional&nbsp;
              <a target="__blank" href="https://iviry.com/">
                Iviry
              </a>
              &nbsp;faced challenges in distributing it to a wider audience.
            </div>
          </div>
        </div>
        <div className="section-content-container">
          <h2>THE PROCESS</h2>
          <div className="process-description">
            Iviry is a tech-focused company that developed software for IT
            specialists, designed to help them manage and maintain IT services
            for their clients. While the software was sophisticated and
            functional, Iviry faced challenges in distributing it to a wider
            audience.
            <div className="process-pointers">
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Partnered with Iviry to understand software capabilities and
                  target audience.
                </div>
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Designed a new website that captured current services and
                  implemented new ones seamlessly
                </div>
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Developed a multi-channel marketing strategy: <br />
                  &gt; SEO-optimized <br /> &gt; content <br /> ○ Partnership
                  outreach
                </div>
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <div>
                  Managed campaign rollouts and monitored performance metrics.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-content-container">
          <div className="results-container">
            <div className="result-description">
              <h2>THE RESULTS</h2>
              The collaboration was a resounding success: <br /> 1.
              Significantly increased user engagement and downloads. <br />
              2. Enabled Iviry to break into new markets, expanding its customer
              base. <br /> 3. Improved to first page for Google Searches
            </div>
            <div className="quotes">
              <img src={quote} alt="" />
              <div className="quote-text">
                Competence from a business partner is a baseline expectation as
                you embark on a project together, and when distinguishable
                expertise upon execution of services is demonstrated the
                experience becomes that much more gratifying. Trust and
                accountability, however, cannot be fully quantified until each
                organization is fully engaged in the doing. On those fronts,
                Innovative Mojo, in the delivery of all the various services
                they provide to our organization, has no equal. We could not
                possibly overstate the value they bring to our business, or the
                peace of mind associated with knowing they are in our corner.
              </div>
              <b>--Joseph Browne</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VslSection10;
