import React from "react";
import check from "../../../../assets/check.svg";
import "./vsl-section5.styles.scss";
const VslSection5 = () => {
  return (
    <div className="section5-component">
      <div className="container">
        <div className="section5-headings">
          <div className="subtext">
            OVER 700 SATISFIED CLIENTS AND 1200+ SUCCESSFUL PROJECTS WITH OVER
            20+ YEARS EXPERIENCE
          </div>
          <h1>
            INNOVATIVE MOJO CRAFTS DIGITAL EXPERIENCES THAT
            <span> USE THE LATEST CODING LANGUAGES</span>
          </h1>
        </div>
        <div className="section5-description">
          <div className="subtext-large">
            Ready To Scale Your Digital Business? Discover The Business Boost
            Blueprint!
          </div>
          <ul>
            <li>
              <img src={check} alt="" />
              Time Saving Solutions
            </li>
            <li>
              <img src={check} alt="" />
              Tech Expertise
            </li>
            <li>
              <img src={check} alt="" />
              Digital Modernization
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VslSection5;
