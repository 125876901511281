import React from "react";
import ImportantFieldAsterik from "../important-field-asterik/important-field-asterik.atom";
import "./textfield-new.styles.scss";

const TextfieldAtomNew = ({
  value,
  setValue,
  redBorder,
  label,
  defaultValue,
  setError,
  error,
  required,
  name,
  errorMessage,
  disabled,
  regularExpression,
  type,
  disable,
  min,
  step,
  id,
  white,
  noborder,
  characterCount,

  ...props
}) => {
  const [focus, setFocus] = React.useState(false);
  const [limit, setLimit] = React.useState(false);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if ((value || value === 0 || props.placeholder || defaultValue) && label) {
      setFocus(true);
    }
  }, [value, props.placeholder, defaultValue, label]);

  React.useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [focus]);

  const handleFocus = (e) => {
    setFocus(true);
  };

  const looseFocus = (e) => {
    if (!value && value !== 0 && !defaultValue && !props.placeholder) {
      setFocus(false);
    }
  };

  const handleChange = (e) => {
    if (!setValue) return;
    e.stopPropagation();
    const inputValue = e.target.value;
    let newValue = inputValue;

    if (characterCount) {
      const inputLength = inputValue.length;
      if (inputLength > characterCount) {
        newValue = inputValue.substring(0, characterCount);
      }
      setLimit(inputLength >= characterCount);
    }

    setValue(newValue);

    let errorCount = 0;
    if (required && newValue.trim() === "") {
      errorCount++;
    }
    if (setError) {
      setError(errorCount > 0);
    }
  };

  return (
    <div
      onClick={handleFocus}
      disabled={disabled}
      className={`textfield-new-atom ${white ? "white" : ""} ${
        noborder ? "no-border" : ""
      }`}
    >
      <label onClick={handleFocus} className={focus ? "focus" : ""}>
        {label} {required && <ImportantFieldAsterik />}
      </label>
      <input
        defaultValue={defaultValue}
        className={`${error || redBorder ? "red-border" : ""} `}
        id={id}
        ref={inputRef}
        disabled={disabled}
        name={name ? name : "text-field"}
        type={type}
        value={value}
        min={min}
        onChange={handleChange}
        onBlur={looseFocus}
        {...props}
      />
      <div className={`error ${!error ? "no-error" : ""}`}>
        {characterCount ? (
          <span className={`character-count ${limit ? "bold" : "normal"} `}>
            Maximum {characterCount} characters ({value.length}/{characterCount}
            )
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TextfieldAtomNew;
