import React, { useState } from "react";

import ImagePreviewIcon from "../../assets/preview.svg";
import { ReactComponent as EditIcon } from "../../assets/big-edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";

import "./image-previewer-and-edit.styles.scss";

const ImagePreviewerAndEdit = ({
  alt,
  id,
  square,
  setError,
  datatest,
  width,
  lightBackground,
  file,
  setFile,
  subText,
  edit,
  smallIcon,
}) => {
  const [largeFile, setLargeFile] = useState(false);
  const inputRef = React.useRef(null);

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const size = Math.round(file.size / 1024) / 1024;

      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
        if (setError) {
          setError("Please select a valid image format");
        } else {
          alert("Please select a valid image format");
        }
        removeImage();
        return false;
      } else if (size > 1) {
        if (setError) {
          setLargeFile(true);

          setError("You can only upload files less than 1MB");
        } else {
          alert("File too large. Please select a file less than 1MB");
        }
        removeImage();
        return false;
      } else if (size < 1) {
        setLargeFile(false);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        if (setError) {
          setError(false);
        }
        setFile(reader.result);
      };
    } else {
      removeImage();
    }
  };

  const removeImage = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setFile("");
    inputRef.current.value = null;
  };

  const handleEdit = () => {
    if (edit) {
      inputRef.current.click();
    }
  };

  return (
    <div className={`image-previewer-and-edit-component`}>
      <div
        className={`image-previewer-and-edit-container
        ${square ? "square" : ""}
        ${smallIcon ? "smallIcon" : ""}
        ${lightBackground ? "light-background" : ""}
        ${file ? "image-exist" : ""}
        ${edit ? "image-edit" : ""}
        ${largeFile ? "large-file" : ""}
      `}
        onClick={handleEdit}
      >
        <img
          id={id}
          src={file ? file : ImagePreviewIcon}
          alt={alt ? alt : "preview"}
        />
        {edit && (
          <span className={`icon-button-container`}>
            <EditIcon className="edit-icon" />
            {file && edit && (
              <DeleteIcon
                onClick={(e) => removeImage(e)}
                title="Remove image"
                className="remove-icon"
              />
            )}
          </span>
        )}
        <input
          data-test={datatest}
          ref={inputRef}
          type="file"
          name={datatest}
          accepts="images/*"
          onChange={handleChange}
          id="myFile"
        />
      </div>

      {subText && <p>{subText}</p>}
    </div>
  );
};

export default ImagePreviewerAndEdit;
