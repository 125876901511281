import React from "react";
import PrimaryButton from "../../../../components/button-primary/button-primary.component";
import { useNavigate } from "react-router-dom";
import "./vsl-section9.styles.scss";
const VslSection9 = () => {
  const navigate = useNavigate();
  return (
    <div className="section9-component">
      <div className="container">
        <div className="subtext">DRIVE REVENUE GROWTH!</div>
        <h1>BECOME A PART OF OUR SUCCESS STORY</h1>

        <div className="section-content">
          <div className="section-content-container">
            <div className="subtext">Tech Expertise</div>
            <h1>WEB DEVELOPMENT </h1>
          </div>

          <div className="section-content-container">
            <div className="subtext">Time Saving Solutions</div>
            <h1>BUSINESS EFFICIENCY</h1>
          </div>
        </div>
        <PrimaryButton onClick={() => navigate("/booking")}>
          <div className="button-flex">
            <h5>Act Now and Receive</h5>
            <div>30 Days of FREE Maintenance</div>
          </div>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default VslSection9;
