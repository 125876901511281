import React from "react";

import { useAsyncDebounce } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon.svg";

import "./globalFilter.styles.scss";
// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  id,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    setValue(globalFilter);
  }, [globalFilter]);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span className="table-filter-input-field-component">
      <div data-test="search-field" className="input-field-component">
        {!value && <SearchIcon className="searchIcon" />}
        <input
          id={`${id}-input`}
          value={value || ""}
          onChange={(e) => {
            e.stopPropagation();
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search ${count} records...`}
        />
      </div>
    </span>
  );
};
export default GlobalFilter;
