import React from 'react'
import arrow from "../../../assets/newpage/arrow.svg"

const Button = ({text}) => {
  return (
    <div>
      <a href="/booking">
      <button className="btncontact ">{text}
          <img className="ml-2 button-arrow" src={arrow} alt=""  />
           </button>
          </a>
         
      
    </div>
  )
}

export default Button
